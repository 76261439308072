import {BehaviorSubject} from "rxjs";
import {filter, take} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class MainMenuNavigationService {

  public navigationEvents$ = new BehaviorSubject<string>('');

  public onActivate(...names:string[]) {
    return this
      .navigationEvents$
      .pipe(
        filter(evt => names.indexOf(evt) !== -1),
        take(1)
      );
  }

  private recreateToggler() {
    let that = this;
    // rejigger the main-menu sub-menu functionality.
    jQuery("#main-menu .toggler").remove(); // remove the togglers so they're inserted properly later.

    var toggler = jQuery('<a class="toggler" href="#"><i class="icon6 icon-toggler icon-arrow-right3" aria-hidden="true"></i><span class="hidden-for-sighted"></span></a>')
      .on('click', function() {
        let target = jQuery(this);
        if (target.hasClass('toggler')) {

          // TODO: Instead of hiding the sidebar move sidebar's contents to submenus and cache it.
          jQuery('#sidebar').toggleClass('-hidden', true);

          jQuery(".menu_root li").removeClass('open')
          jQuery(".menu_root").removeClass('open').addClass('closed');

          let targetLi = target.closest('li')
          targetLi
            .addClass('open')
            .find('li > a:first, .tree-menu--title:first').first().focus();

          console.log("Activating " + targetLi.data('name'));
          that.navigationEvents$.next(targetLi.data('name'));
        }
        return false;
      });
    toggler.attr('title', I18n.t('js.project_menu_details'));

    return toggler;
  }

  private wrapMainItem() {
    var mainItems = jQuery('#main-menu li > a').not('ul ul a');

    mainItems.wrap((index:number) => {
      var item = mainItems[index];
      var elementId = item.id;

      var wrapperElement = jQuery('<div class="main-item-wrapper"/>')

      // inherit element id
      if (elementId) {
        wrapperElement.attr('id', elementId + '-wrapper')
        //console.log(elementId);
        if (elementId!.indexOf("work-packages") >= 0) {
          var _href = jQuery('#' + elementId).attr("href");
          //console.log(_href);
          //jQuery('#' + elementId).attr("href", _href + '?query_props={"c"%3A["id"%2C"type"%2C"cf_25"%2C"subject"%2C"status"%2C"assigned_to"%2C"responsible"%2C"cf_21"%2C"cf_29"%2C"cf_30"%2C"cf_38"%2C"cf_39"%2C"cf_24"%2C"start_date"%2C"due_date"]%2C"hi"%3Afalse%2C"hl"%3A"inline"%2C"hla"%3A["status"]%2C"g"%3A""%2C"t"%3A"id%3Aasc"%2C"f"%3A[{"n"%3A"status"%2C"o"%3A"o"%2C"v"%3A[]}]}');
          jQuery('#' + elementId).attr("href", _href + '?query_props=%7B%22c%22%3A%5B%22id%22%2C%22type%22%2C%22cf_25%22%2C%22subject%22%2C%22cf_44%22%2C%22status%22%2C%22assigned_to%22%2C%22responsible%22%2C%22cf_21%22%2C%22cf_29%22%2C%22cf_30%22%2C%22cf_38%22%2C%22cf_39%22%2C%22cf_24%22%2C%22start_date%22%2C%22due_date%22%5D%2C%22hi%22%3Atrue%2C%22hl%22%3A%22inline%22%2C%22hla%22%3A%5B%22status%22%5D%2C%22g%22%3A%22%22%2C%22t%22%3A%22id%3Aasc%22%2C%22f%22%3A%5B%7B%22n%22%3A%22status%22%2C%22o%22%3A%22*%22%2C%22v%22%3A%5B%5D%7D%5D%7D');
        }
      }

      return wrapperElement;
    });
  }

  register() {

    // Wrap main item
    this.wrapMainItem();

    // Scroll to the active item
    const selected = jQuery('.main-item-wrapper a.selected');
    if (selected.length > 0) {
      selected[0].scrollIntoView();
    }


    // Recreate toggler
    const toggler = this.recreateToggler();

    // Emit first active
    let active = jQuery('#main-menu .menu_root > li.open').data('name');
    let activeRoot = jQuery('#main-menu .menu_root.open > li').data('name');
    if (active || activeRoot) {
      this.navigationEvents$.next(active || activeRoot);
    }

    jQuery('#main-menu li:has(ul) .main-item-wrapper > a').not('ul ul a')
    // 1. unbind the current click functions
      .unbind('click')
      // 2. wrap each in a span that we'll use for the new click element
      .wrapInner('<span class="ellipsis"/>')
      // 3. reinsert the <span class="toggler"> so that it sits outside of the above
      .after(toggler);

    function navigateUp(this:any, event:any) {
      event.preventDefault();
      var target = jQuery(this);
      jQuery(target).parents('li').first().removeClass('open');
      jQuery(".menu_root").removeClass('closed').addClass('open');

      target.parents('li').first().find('.toggler').first().focus();

      // TODO: Instead of hiding the sidebar move sidebar's contents to submenus and cache it.
      jQuery('#sidebar').toggleClass('-hidden', false);
    }

    jQuery('#main-menu ul.main-menu--children').each(function(_i, child) {
      var title = jQuery(child).parents('li').find('.main-item-wrapper .menu-item--title').contents()[0].textContent;
      var parentURL = jQuery(child).parents('li').find('.main-item-wrapper > a').attr('href');
      if ((parentURL!.indexOf("work_packages") >= 0) && !(parentURL!.indexOf("query_props") >= 0)) {
          parentURL += '?query_props=%7B%22c%22%3A%5B%22id%22%2C%22type%22%2C%22cf_25%22%2C%22subject%22%2C%22cf_44%22%2C%22status%22%2C%22assigned_to%22%2C%22responsible%22%2C%22cf_21%22%2C%22cf_29%22%2C%22cf_30%22%2C%22cf_38%22%2C%22cf_39%22%2C%22cf_24%22%2C%22start_date%22%2C%22due_date%22%5D%2C%22hi%22%3Atrue%2C%22hl%22%3A%22inline%22%2C%22hla%22%3A%5B%22status%22%5D%2C%22g%22%3A%22%22%2C%22t%22%3A%22id%3Aasc%22%2C%22f%22%3A%5B%7B%22n%22%3A%22status%22%2C%22o%22%3A%22*%22%2C%22v%22%3A%5B%5D%7D%5D%7D';
          console.log(parentURL);
      }
      var header = jQuery('<div class="main-menu--children-menu-header"></div>');
      var upLink = jQuery('<a class="main-menu--arrow-left-to-project" href="#"><i class="icon-arrow-left1" aria-hidden="true"></i></a>');
      var parentLink = jQuery('<a href="' + parentURL + '" class="main-menu--parent-node ellipsis">' + title + '</a>');
      upLink.attr('title', I18n.t('js.label_up'));
      upLink.on('click', navigateUp);
      header.append(upLink);
      header.append(parentLink);
      jQuery(child).before(header);
    });

    if (jQuery('.menu_root').hasClass('closed')) {
      // TODO: Instead of hiding the sidebar move sidebar's contents to submenus and cache it.
      jQuery('#sidebar').toggleClass('-hidden', true);
    }
  }

}

import {Component, Input, SimpleChanges} from '@angular/core';
import {WorkPackageTableConfiguration} from 'core-components/wp-table/wp-table-configuration';
import {GroupObject} from 'core-app/modules/hal/resources/wp-collection-resource';
import {Chart, ChartOptions, ChartType} from 'chart.js';
import {Color, Label } from 'ng2-charts';
import {I18nService} from "core-app/modules/common/i18n/i18n.service";
import * as jsPDF from 'jspdf';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';

export interface WorkPackageEmbeddedGraphDataset {
  label:string;
  queryProps:any;
  queryId?:number|string;
  groups?:GroupObject[];
}
interface ChartDataSet {
  label:string;
  data:number[];
}

@Component({
  selector: 'wp-embedded-graph',
  templateUrl: './wp-embedded-graph.html',
  styleUrls: ['./wp-embedded-graph.component.sass'],
})
export class WorkPackageEmbeddedGraphComponent {
  @Input() public datasets:WorkPackageEmbeddedGraphDataset[];
  @Input('chartOptions') public inputChartOptions:ChartOptions;
  @Input('chartType') chartType:ChartType = 'horizontalBar';

  public configuration:WorkPackageTableConfiguration;
  public error:string|null = null;

  public chartHeight = '100%';
  public chartLabels:string[] = [];
  public chartData:ChartDataSet[] = [];
  public chartOptions:ChartOptions;
  public initialized = false;
  public maxYValue = 0;

  public barChartColors: Color[] = [
    //{ backgroundColor: "rgb(23, 125, 255)" },
    { backgroundColor: ["#1d7af3","#f3545d","#fdaf4b"] },
    { backgroundColor: "rgb(211, 211, 211)" },
  ]

  public text = {
    noResults: this.i18n.t('js.work_packages.no_results.title'),
  };

  constructor(readonly i18n:I18nService) {}

  ngOnChanges(changes:SimpleChanges) {
    if (changes.datasets) {
      this.setChartOptions();
      this.updateChartData();


      if (!changes.datasets.firstChange) {
        this.initialized = true;
      }
    } else if (changes.chartType) {
      this.setChartOptions();
    }
  }

  private updateChartData() {
    let uniqLabels = _.uniq(this.datasets.reduce((array, dataset) => {
      let groups = (dataset.groups || []).map((group) => group.value) as any;
      return array.concat(groups);
    }, [])) as string[];

    let newLabels = ['DA TESTARE', 'IN ESECUZIONE', 'ESITO POSITIVO', 'ESITO NEGATIVO', 'SOSPESO/RINVIATO'];
    let statusOView = "1: status";
    let CurValue = (<HTMLSelectElement>document.getElementById('uatgraph')).value;
    uniqLabels = uniqLabels.filter((label) => {
      if (!label) {
        return false;
      } else if(label.indexOf("New") !== -1) {
        return false;
      } else if(label.indexOf("Confirmed") !== -1) {
        return false;
      } else if(label.indexOf("To be scheduled") !== -1) {
        return false;
      } else if(label.indexOf("In progress") !== -1) {
        return false;
      //} else if(label.indexOf("Delayed") !== -1) {
      //  return false;
      } else if(label.indexOf("Rejected") !== -1) {
        return false;
      } else {
        return label.indexOf("-") === -1;
      }
    });

    if (CurValue.indexOf(statusOView) !== -1) {
      //todo: could be a problem
      if ((uniqLabels.indexOf('In specification') > -1)||(uniqLabels.indexOf('Specified') > -1)||(uniqLabels.indexOf('Scheduled') > -1)||(uniqLabels.indexOf('In Testing') > -1)||(uniqLabels.indexOf('Test failed') > -1)||(uniqLabels.indexOf('In developing') > -1)||(uniqLabels.indexOf('Re_testing') > -1)||(uniqLabels.indexOf('Limbo') > -1)||(uniqLabels.indexOf('Delayed') > -1)||(uniqLabels.indexOf('Indisponibile') > -1)||(uniqLabels.indexOf('Disponibile') > -1)||(uniqLabels.indexOf('In esecuzione') > -1)||(uniqLabels.indexOf('Esito positivo') > -1)||(uniqLabels.indexOf('Esito negativo') > -1)||(uniqLabels.indexOf('IT') > -1)||(uniqLabels.indexOf('Validato') > -1)||(uniqLabels.indexOf('Sospeso') > -1)||(uniqLabels.indexOf('Rinviato') > -1)) {
        uniqLabels.push(...newLabels);
      }
    }

    var newColors = new Array();
    if (uniqLabels.indexOf('In specification') > -1) { newColors.push("#70F8BA");}//2
    if (uniqLabels.indexOf('Specified') > -1) { newColors.push("#337CA0");}//3
    if (uniqLabels.indexOf('Scheduled') > -1) { newColors.push("#EAD94C");}//6
    if (uniqLabels.indexOf('Solved') > -1) { newColors.push("#EAD94C");}//7
    if (uniqLabels.indexOf('In testing') > -1) { newColors.push("#F9C846");}//9
    if (uniqLabels.indexOf('Done') > -1) { newColors.push("#109648");}//10
    if (uniqLabels.indexOf('Test failed') > -1) { newColors.push("#E26D5C");}//11
    if (uniqLabels.indexOf('Closed') > -1) { newColors.push("#EBEBEB");}//12
    if (uniqLabels.indexOf('In developing') > -1) { newColors.push("#63474D");}//13
    if (uniqLabels.indexOf('Re_testing') > -1) { newColors.push("#F9C846");}//17
    if (uniqLabels.indexOf('Limbo') > -1) { newColors.push("#337CA0");}//18
    if (uniqLabels.indexOf('Delayed') > -1) { newColors.push("#FACFAD");}//19
    if (uniqLabels.indexOf('Indisponibile') > -1) { newColors.push("#00B0F0");}//23
    if (uniqLabels.indexOf('Disponibile') > -1) { newColors.push("#175A8E");}//24
    if (uniqLabels.indexOf('In esecuzione') > -1) { newColors.push("#FFCC00");}//21
    if (uniqLabels.indexOf('Esito positivo') > -1) { newColors.push("#35C53F");}//25
    if (uniqLabels.indexOf('Esito negativo') > -1) { newColors.push("#FF3300");}//26
    if (uniqLabels.indexOf('IT') > -1) { newColors.push("#E20074");}//27
    if (uniqLabels.indexOf('Validato') > -1) { newColors.push("#A9A9A9");}//28
    if (uniqLabels.indexOf('Sospeso') > -1) { newColors.push("#000000");}//29
    if (uniqLabels.indexOf('Rinviato') > -1) { newColors.push("#000000");}//30
    newColors.push("#DC6BAD");
    let newBgColor : Color = { backgroundColor: newColors };
    this.barChartColors[0] = newBgColor;

    let labelCountMaps = this.datasets.map((dataset) => {
      let countMap = (dataset.groups || []).reduce((hash, group) => {
        hash[group.value] = group.count;
        return hash;
      }, {} as any);

      if (CurValue.indexOf(statusOView) !== -1) {
      return {
        label: dataset.label,
        data: uniqLabels.map((label) => {
            if (label === 'IN ESECUZIONE') {
              return ((typeof countMap['In testing'] === 'undefined')?0:countMap['In testing']) + ((typeof countMap['In esecuzione'] === 'undefined')?0:countMap['In esecuzione']) || 0;
            } else if (label === 'DA TESTARE') {
              return ((typeof countMap['In specification'] === 'undefined')?0:countMap['In specification']) + ((typeof countMap['Specified'] === 'undefined')?0:countMap['Specified']) + ((typeof countMap['Scheduled'] === 'undefined')?0:countMap['Scheduled']) + ((typeof countMap['Limbo'] === 'undefined')?0:countMap['Limbo']) + ((typeof countMap['Indisponibile'] === 'undefined')?0:countMap['Indisponibile']) + ((typeof countMap['Disponibile'] === 'undefined')?0:countMap['Disponibile']) || 0;
            } else if (label === 'SOSPESO/RINVIATO') {
              return ((typeof countMap['Suspended / Delayed'] === 'undefined')?0:countMap['Suspended / Delayed']) + ((typeof countMap['Sospeso'] === 'undefined')?0:countMap['Sospeso']) + ((typeof countMap['Rinviato'] === 'undefined')?0:countMap['Rinviato']) || 0;
            } else if (label === 'ESITO POSITIVO') {
              return ((typeof countMap['Done'] === 'undefined')?0:countMap['Done']) + ((typeof countMap['Closed'] === 'undefined')?0:countMap['Closed']) + ((typeof countMap['Esito positivo'] === 'undefined')?0:countMap['Esito positivo']) + ((typeof countMap['Validato'] === 'undefined')?0:countMap['Validato']) || 0;
            } else if (label === 'ESITO NEGATIVO') {
              return ((typeof countMap['Test failed'] === 'undefined')?0:countMap['Test failed']) + ((typeof countMap['In developing'] === 'undefined')?0:countMap['In developing']) + ((typeof countMap['Re_testing'] === 'undefined')?0:countMap['Re_testing']) + ((typeof countMap['Esito negativo'] === 'undefined')?0:countMap['Esito negativo']) + ((typeof countMap['IT'] === 'undefined')?0:countMap['IT']) || 0;
            } else {
              return countMap[label] || 0;
            }
        })
      };
      } else {
      return {
        label: dataset.label,
        data: uniqLabels.map((label) => { return countMap[label] || 0; })
      };
      }
    });

    uniqLabels = uniqLabels.map((label) => {
      if (!label) {
        return this.i18n.t('js.placeholders.default');
      } else {
        return label;
      }
    });

    this.setHeight();

    // keep the array in order to update the labels
    this.chartLabels.length = 0;
    if (CurValue.indexOf(statusOView) !== -1) {
      this.chartLabels.push(...newLabels);
    } else {
    this.chartLabels.push(...uniqLabels);
    }
    this.chartData.length = 0;
    //console.log(uniqLabels.length);
    //console.log(labelCountMaps);
    if (CurValue.indexOf(statusOView) !== -1) {
        let skipNum = uniqLabels.length - 5;
        //console.log(skipNum);
        if (skipNum > 0) {
          var newColors = new Array();
          if (uniqLabels.indexOf('DA TESTARE') > -1) { newColors.push("#0000CD");}
          if (uniqLabels.indexOf('IN ESECUZIONE') > -1) { newColors.push("#FF8C00");}
          if (uniqLabels.indexOf('ESITO POSITIVO') > -1) { newColors.push("#32CD32");}
          if (uniqLabels.indexOf('ESITO NEGATIVO') > -1) { newColors.push("#DC143C");}
          if (uniqLabels.indexOf('SOSPESO/RINVIATO') > -1) { newColors.push("#696969");}
          newColors.push("#DC6BAD");
          let newBgColor : Color = { backgroundColor: newColors };
          this.barChartColors[0] = newBgColor;

          let newLabelCountMaps = labelCountMaps.map((value: { label: string; data: any[]; }, index: number, array: { label: string; data: any[]; }[]) => {
            //console.log(value.label);
            //console.log(value.data);
            //console.log(index);

            return {
                label: value.label,
                data: value.data.splice(skipNum)
            }
          });
          this.chartData.push(...newLabelCountMaps);
        } else {
          this.chartData.push(...labelCountMaps);
        }
    } else {
      this.chartData.push(...labelCountMaps);
    }

    if (this.chartType === 'horizontalBar') {
      for(var i = 0; i < this.chartData.length; i++) {
        if(Math.max(...this.chartData[i].data) > this.maxYValue) {
          this.maxYValue = Math.max(...this.chartData[i].data);
        }
      }
    }
    //console.log("t max value is " + this.maxYValue);
  }

  protected setChartOptions() {
    let defaults = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        // Only display legends if more than one dataset is provided.
        display: (this.datasets.length > 1) || (this.datasets.length > 0 && this.chartType != 'horizontalBar')
      }
    };

    let chartTypeDefaults:ChartOptions = {};

    if (this.chartType === 'horizontalBar') {
      //console.log("max value is " + this.maxYValue + 100);
      chartTypeDefaults = {
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              callback: (value:number) => {
                if (Math.floor(value) === value) {
                  return value;
                } else {
                  return 0;
                }
              },
              suggestedMax: 300
            }
          }],
            yAxes:
          [{
            stacked: true
          }]
        }
      };
    }

    var perOptions = {
      plugins: {
        datalabels: {
          /*formatter: (value: any, ctx: any) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data: any) => {
              sum += data;
            });
            let percentage = (value*100 / sum).toFixed(1)+"%";
            return percentage;
          },*/
          //color: '#0e0e0e',
          anchor: 'end',
          align: 'end',
          //offset: 16,
          //font: { size: 6 }
        }
      }
    };

    var offsetOptions = {
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 0,
        }
      }
    };


    //if (this.chartType === 'doughnut') {
    if (this.chartType === 'pie') {
      this.chartOptions = Object.assign({}, defaults, chartTypeDefaults, this.inputChartOptions, perOptions);
    } else {
      this.chartOptions = Object.assign({}, defaults, chartTypeDefaults, this.inputChartOptions, offsetOptions);
    }
  }

  public pieChartPlugins = [{
    beforeInit: function(chart: any, options: any) {
        chart.legend.afterFit = function() {
          this.height += 5; // must use `function` and not => because of `this`
        };
    }
  }];

  public get hasDataToDisplay() {
    return this.chartData.length > 0 && this.chartData.some(set => set.data.length > 0);
  }

  private setHeight() {
    if (this.chartType === 'horizontalBar' && this.datasets && this.datasets[0]) {
      let labels:string[] = [];
      this.datasets.forEach(d => d.groups!.forEach(g => {
        if (!labels.includes(g.value)) {
          labels.push(g.value);
        }
      }));
      let height = labels.length * 40;

      if (this.datasets.length > 1) {
        // make some more room for the legend
        height += 40;
      }

      // some minimum height e.g. for the labels
      height += 40;

      this.chartHeight = `${height}px`;
    } else if (this.chartType === 'pie') {
      this.chartHeight = '90%';
    } else {
      this.chartHeight = '100%';
    }
  }

/**
 * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
 *
 * @param {HtmlElement} canvas
 * @param {int} width
 * @param {int} height
 * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
 */
public resample_single(canvas:HTMLCanvasElement, width:number, height:number, resize_canvas:boolean) {
  var width_source = canvas.width;
  var height_source = canvas.height;
  width = Math.round(width);
  height = Math.round(height);

  var ratio_w = width_source / width;
  var ratio_h = height_source / height;
  var ratio_w_half = Math.ceil(ratio_w / 2);
  var ratio_h_half = Math.ceil(ratio_h / 2);

  var ctx = canvas.getContext("2d");
  var img = ctx!.getImageData(0, 0, width_source, height_source);
  var img2 = ctx!.createImageData(width, height);
  var data = img.data;
  var data2 = img2.data;

  for (var j = 0; j < height; j++) {
    for (var i = 0; i < width; i++) {
      var x2 = (i + j * width) * 4;
      var weight = 0;
      var weights = 0;
      var weights_alpha = 0;
      var gx_r = 0;
      var gx_g = 0;
      var gx_b = 0;
      var gx_a = 0;
      var center_y = (j + 0.5) * ratio_h;
      var yy_start = Math.floor(j * ratio_h);
      var yy_stop = Math.ceil((j + 1) * ratio_h);
      for (var yy = yy_start; yy < yy_stop; yy++) {
        var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
        var center_x = (i + 0.5) * ratio_w;
        var w0 = dy * dy; //pre-calc part of w
        var xx_start = Math.floor(i * ratio_w);
        var xx_stop = Math.ceil((i + 1) * ratio_w);
        for (var xx = xx_start; xx < xx_stop; xx++) {
          var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
          var w = Math.sqrt(w0 + dx * dx);
          if (w >= 1) {
            //pixel too far
            continue;
          }
          //hermite filter
          weight = 2 * w * w * w - 3 * w * w + 1;
          var pos_x = 4 * (xx + yy * width_source);
          //alpha
          gx_a += weight * data[pos_x + 3];
          weights_alpha += weight;
          //colors
          if (data[pos_x + 3] < 255)
            weight = weight * data[pos_x + 3] / 250;
          gx_r += weight * data[pos_x];
          gx_g += weight * data[pos_x + 1];
          gx_b += weight * data[pos_x + 2];
          weights += weight;
        }
      }
      data2[x2] = gx_r / weights;
      data2[x2 + 1] = gx_g / weights;
      data2[x2 + 2] = gx_b / weights;
      data2[x2 + 3] = gx_a / weights_alpha;
    }
  }
  //clear and resize canvas
  if (resize_canvas === true) {
    canvas.width = width;
    canvas.height = height;
  } else {
    ctx!.clearRect(0, 0, width_source, height_source);
  }

  //draw
  ctx!.putImageData(img2, 0, 0);
}

  public genPDF(event:JQuery.TriggeredEvent) {
      // get size of report page
      var reportPageHeight = 400 + 10;//jQuery('#reportPage').innerHeight();
      var reportPageWidth = 860 + 10;//jQuery('#reportPage').innerWidth();
      console.log(jQuery('#reportPage').innerHeight());
      console.log(jQuery('#reportPage').innerWidth());

      // create a new canvas object that we will populate with all other canvas objects
      var pdfCanvas = jQuery('<canvas />').attr({
          id: "canvaspdf",
          width: reportPageWidth!,
          height: reportPageHeight!
      });

      // keep track canvas position
      var pdfcanvas = <HTMLCanvasElement> jQuery(pdfCanvas)[0];
      var pdfctx = pdfcanvas!.getContext('2d');
      pdfctx!.fillStyle = "white";
      pdfctx!.fillRect(0, 0, pdfcanvas.width, pdfcanvas.height);
      var pdfctxX = 0;
      var pdfctxY = 0;
      var buffer = 100;

      if (pdfctx == null) return;
      //console.log(pdfctx);

      // for each chart.js chart
      jQuery("#myChart").each(function(index) {
          // get the chart height/width
          if (jQuery(this).innerHeight()! < 200) {
            var canvasHeight = 200;
          } else {
            var canvasHeight = 400;//jQuery(this).innerHeight()!*1.2;//320*1.2;
          }
          var canvasWidth = 860;//jQuery(this).innerWidth()!*1.2;//306*2.8;
          console.log('inner: ' + jQuery(this).innerHeight());
          console.log('inner: ' + jQuery(this).innerWidth());

          // draw the chart into the new canvas
          pdfctx!.drawImage(<HTMLCanvasElement> jQuery(this)[0], pdfctxX, pdfctxY, canvasWidth!, canvasHeight!);
          pdfctxX += canvasWidth! + buffer;
          pdfctxY += canvasHeight! + buffer;
      });
      //this.resample_single(pdfcanvas, 860, 384, true);

      // create new pdf and add our new canvas as an image
      //var pdf = new jsPDF('l', 'pt', [reportPageWidth! + 200, reportPageHeight! + 200]);
      var pdf = new jsPDF('l', 'mm', 'a4');
      pdf.addImage(pdfcanvas, 'PNG', 34, 70);

      var today = new Date().toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      pdf.setFontSize(28);
      pdf.text(108, 26, "UAT STATISTICS");

      pdf.setFontSize(14);
      pdf.text(121, 40, "Package: ");
      pdf.setFontStyle('bold');
      pdf.text(144, 40, jQuery('#projects-menu').attr('title'));

      pdf.setFontStyle('normal');
      pdf.text(121, 48, "Data: ");
      pdf.setFontStyle('bold');
      pdf.text(135, 48, today);

      pdf.setFontStyle('normal');
      let CurValue = (<HTMLSelectElement>document.getElementById('uatgraph')).value;
      if (CurValue.indexOf("0: status") !== -1) {
        var title = "Stato";
      } else if (CurValue.indexOf("1: status") !== -1) {
        var title = "Status Overview";
      } else {
        var title = "Assegnatario";
      }
      pdf.text(121, 56, "Raggruppamento:");
      pdf.setFontStyle('bold');
      //pdf.text(161, 56, CurValue.split(":").pop());
      pdf.text(163, 56, title);

      pdf.setFontStyle('normal');
      pdf.setFontSize(22);
      var newdat = "Kayrhos     UAT Management";
      pdf.text(100, 200, newdat);

      var img = new Image()
      img.src = '/kayrhos.png';
      pdf.addImage(img, 'png', 129, 193);

      // download the pdf
      pdf.save('uat_graph.pdf');
  }
}

<div id="reportPage" class="work-packages-embedded-view--container loading-indicator--location" #graphContainer [ngStyle]="{height: chartHeight}">
  <canvas id="myChart" baseChart
          [datasets]="chartData"
          [labels]="chartLabels"
          [chartType]="chartType"
          [options]="chartOptions"
          [colors]="barChartColors"
          [plugins]="pieChartPlugins"
          *ngIf="hasDataToDisplay">
  </canvas>
  <no-results *ngIf="!hasDataToDisplay && initialized"
              [title]="text.noResults">
  </no-results>
</div>
<br/><br/><br/>
<ng-container *ngIf="chartType==='horizontalBar'">
<a href="#" id="downloadPdf" (click)="genPDF($event)">Scarica in formato PDF</a>
</ng-container>

<div *ngIf="workPackage"
     class="wp-new-top-row">
  <div class="work-packages--status-selector wp-new-top-row--element">
    <editable-attribute-field [resource]="workPackage"
                   [displayFieldOptions]="{ colorize: false }"
                   [wrapperClasses]="'wp-new-top-row--status -no-label'"
                   [fieldName]="'status'">
    </editable-attribute-field>
  </div>
  <div class="work-packages--type-selector wp-new-top-row--element" style="pointer-events:none;font-size:12px;margin-left:15px">
      Nota: settare il tipo all'interno dell'attività o nell'elenco della tabella<br/>
      perché i numeri di relazione della gerarchia è arrivato al limite
  </div>
</div>

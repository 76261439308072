<ng-select #ngSelectComponent
           [(ngModel)]="model"
           [items]="availableValues"
           [ngClass]="classes"
           [addTag]="createAllowed"
           [virtualScroll]="true"
           [required]="required"
           [clearable]="!required"
           [disabled]="disabled"
           [typeahead]="typeahead"
           [clearOnBackspace]="false"
           [appendTo]="appendTo"
           [hideSelected]="hideSelected"
           [id]="id"
           (change)="changeModel($event)"
           (open)="opened()"
           (close)="closed()"
           (keydown)="keyPressed($event)"
           bindLabel="name">
  <ng-template ng-tag-tmp let-search="searchTerm">
    <b [textContent]="text.add_new_action"></b>: {{search}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <ng-container *ngIf="item.name!='-'&&item.name!='-.'&&item.name!='-,'">
    <div [ngOptionHighlight]="search" class="ng-option-label ellipsis testa">{{ item.name }}</div>
    </ng-container>
  </ng-template>
</ng-select>
